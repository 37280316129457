import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <section className="banner-terms Terms">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Terms & Condition</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link to="/services">Terms & Condition</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="main-privacy ">
        <div className="container">
          <div className="row">
            <h1>Terms and Conditions</h1>
            <p>
              These terms and conditions govern the use of the NMP Packers and
              movers website and the services provided by NMP Packers and
              movers. You accept these terms and conditions if you use our
              website or services.
            </p>
            <p>Services Offered</p>
            <p>
              NMP Packers and movers provide moving services, which may include
              packing, loading, transportation, unloading, and unpacking of your
              belongings. We may also offer storage and warehousing services.
              Our services are subject to availability and we reserve the right
              to refuse service to anyone.
            </p>

            <h2>Booking and Payment</h2>
            <p>
              Name, address, phone number, email address, and specifics of your
              relocation needs are required in order to reserve our services. We
              may require a deposit or full payment in advance to secure your
              booking. Payment can be made by cash, credit card, or other
              payment methods agreed upon by NMP Packers and movers.
            </p>

            <h2>Cancellation and Rescheduling</h2>

            <p>
              If you need to cancel or reschedule your booking, please notify us
              as soon as possible. We may charge a cancellation fee or
              rescheduling fee, depending on the timing of your cancellation or
              rescheduling. Liability
            </p>
            <p>
              We will take reasonable care in providing our services, but we
              will not be liable for any loss, damage, or injury that may arise
              from the use of our services. We recommend that you purchase
              insurance to cover any loss or damage to your belongings during
              the move.
            </p>

            <h2>Customer Responsibilities</h2>
            <p>
              You are responsible for ensuring that your belongings are properly
              packed and ready for the move. You are also responsible for
              providing accurate information about your moving requirements and
              ensuring that there is adequate access to your property for our
              movers. Intellectual Property
            </p>

            <p>
              The content and design of our website are the property of NMP
              Packers and movers and may not be used or reproduced without our
              permission.
            </p>
            <h2>Privacy</h2>
            <p>
              We value your privacy and will only utilize your personal data in
              ways that are permitted by our privacy policy. Changes to Terms
              and Conditions.
            </p>

            <p>
              We reserve the right to modify these terms and conditions at any
              time. Any updates will be posted on our website together with the
              updated terms and conditions to let you know about them.
            </p>
            <p>
              If you have any questions or concerns about our Terms and
              Conditions, please contact us at noidamuvers@gmail.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
