import React, { useState, useEffect, useRef } from "react";
import hero from "../Assets/check.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import nblogo from "../Assets/nb-protect.webp";
import logotwo from "../Assets/clock.webp";
import icon1 from "../Assets/hassle-icon.webp";
import icon2 from "../Assets/reliable-driver.webp";
import logos from "../Assets/map_11622902 (1).webp";
import location from "../Assets/location.webp";
import driver from "../Assets/driving.webp";
import truck from "../Assets/truck.webp";
import work1 from "../Assets/heavy-lifting.webp";
import work2 from "../Assets/instant-quote.webp";
import work3 from "../Assets/quality-service-expert.webp";
import work4 from "../Assets/shifting-requirement.webp";
import image1 from "../Assets/n1.webp";
import image2 from "../Assets/hero.webp";
import image3 from "../Assets/lowest-price.webp";
import image4 from "../Assets/quality-service.webp";
import image5 from "../Assets/reschedule-cancellation.webp";
import image6 from "../Assets/move-manager.webp";
import image7 from "../Assets/professional-labour.webp";
import Table from "react-bootstrap/Table";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "../Form/Form";

const Left = ({ activeTab, setActiveTab }) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleClick = (itemId) => {
    setActiveItem(itemId); // Set the active item
    setActiveTab(itemId);

    if (window.innerWidth < 768) {
      window.scrollTo({
        top: 160,
        behavior: "smooth",
      });
    }
  };

  const videoRef = useRef(null);

  useEffect(() => {
    // Ensure the video reference exists
    if (videoRef.current) {
      videoRef.current.play(); // Play the video
    }
  }, []);

  const sliderData = [
    {
      id: 1,
      logo: nblogo,
      brandName: "NMP Protect",
      title: "Household damage protection",
    },
    {
      id: 2,
      logo: logotwo,
      brandName: "4.8 / 5 Rating",
      title: "Timely Pickup & Delivery",
    },
    {
      id: 3,
      logo: icon1,
      brandName: "4.9 / 5 Rating",
      title: "Reliable Packaging",
    },
    {
      id: 4,
      logo: icon2,
      brandName: "Price Match Guarantee",
      title: "100% price and  guarantee",
    },
  ];

  const settings = {
    dots: true, // Show slider dots
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768, // Adjust as per your phone width breakpoint
        settings: {
          slidesToShow: 2, // Change slides to show for smaller screens
        },
      },
    ],
  };

  return (
    <>
      <div className="hero-left">
        <h4>India’s Most Reliable Packing Services</h4>
        <p>Packing or Unpacking Rental services that fit your budget!</p>
      </div>

      <section className="container-fluid slider-section">
        <div className="slider-container">
          <Slider {...settings}>
            {sliderData.map((item) => (
              <div key={item.id}>
                <div className="slider-left">
                  <img src={item.logo} alt={item.brandName} />
                  <div className="slider-right">
                    <h3>{item.brandName}</h3>
                    <p>{item.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <div className="display-form">
        <Form activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

      <div className="hero-img">
        {/* <video className="video-main" ref={videoRef} autoPlay loop muted>
          <source src={moversonly} type="video/mp4" />
        </video> */}
        <img src={hero} alt="" />
      </div>

      <div className="outer-main-div">
        <h4>Services We Offer</h4>
        <div className="outer-div">
          <div
            className={`inner-div ${activeItem === 1 ? "active" : ""}`}
            onClick={() => {
              handleClick(1);
            }}
          >
            <h6>Upto 25% off</h6>
            <img src={logos} alt="" />
            <p>Within City</p>
          </div>
          <div
            className={`inner-div ${activeItem === 2 ? "active" : ""}`}
            onClick={() => {
              handleClick(2);
            }}
          >
            <h6>Upto 25% off</h6>
            <img src={location} alt="" />
            <p>Out of City</p>
          </div>
          <div
            className={`inner-div ${activeItem === 3 ? "active" : ""}`}
            onClick={() => {
              handleClick(3);
            }}
          >
            <h6>Upto 25% off</h6>
            <img src={driver} alt="" />
            <p>City Tempo</p>
          </div>
          <div
            className={`inner-div ${activeItem === 4 ? "active" : ""}`}
            onClick={() => {
              handleClick(4);
            }}
          >
            <h6>Upto 25% off</h6>
            <img src={truck} alt="" />
            <p>Vehicle Shifting</p>
          </div>
        </div>
      </div>

      <section>
        <div className="outer-main-div mt-4">
          <h4>How it Works?</h4>
          <div className="main-inner-work">
            <div className="inner-work">
              <img src={work4} alt="" />
              <div className="work-text">
                <h6>Describe your needs for shifting to us.</h6>
                <p>Tell us where and when you would like to move, please.</p>
              </div>
            </div>
            <div className="inner-work">
              <img src={work2} alt="" />
              <div className="work-text">
                <h6>Request a Free Instant Quote Right Now</h6>
                <p>
                  Get the most competitive quotation for your relocation right
                  now, no questions asked.
                </p>
              </div>
            </div>
            <div className="inner-work">
              <img src={work3} alt="" />
              <div className="work-text">
                <h6>Appoint a Superior Customer Support Expert</h6>
                <p>
                  A trained service professional will be assigned to your move
                  to guarantee a secure transition.
                </p>
              </div>
            </div>
            <div className="inner-work">
              <img src={work1} alt="" />
              <div className="work-text">
                <h6>Give us the heavy lifting.</h6>
                <p>
                  Enjoy hassle-free, prompt delivery of your household goods.
                </p>
              </div>
            </div>
            <div className="inner-images">
              <img src={image2} alt="" />
              <img src={image1} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="outer-main-div mt-4">
          <h4>Why NMP Packers and movers in Noida?</h4>
          <div className="main-inner-work">
            <div className="inner-work-images">
              <img src={image3} alt="" />
              <div className="work-text">
                <h6>Guaranteed Lowest Price</h6>
                <p>
                  Moving at a cost you can afford: we will match any quote from
                  a rival company.
                </p>
              </div>
            </div>
            <div className="inner-work-images">
              <img src={image4} alt="" />
              <div className="work-text">
                <h6>Top-Rated Services</h6>
                <p>Reliable and Safe Moving and Packing Services</p>
              </div>
            </div>
            <div className="inner-work-images">
              <img src={image5} alt="" />
              <div className="work-text">
                <h6>Replan your move at any time.</h6>
                <p>Change the shift's date to accommodate your schedule.</p>
              </div>
            </div>
            <div className="inner-work-images">
              <img src={image6} alt="" />
              <div className="work-text">
                <h6>Supporting Assistance</h6>
                <p>Committed help to ensure prompt resolution of inquiries</p>
              </div>
            </div>

            <div className="inner-work-images">
              <img src={image7} alt="" />
              <div className="work-text">
                <h6>Expert Labor</h6>
                <p>packing and relocating your possessions with skill</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="outer-main-div mt-4">
          <h4>Service Comparison</h4>

          <div>
            <Table responsive="sm" className="main-tables">
              <thead>
                <tr>
                  <th className="text-light-grey">Services</th>
                  <th className="text-light-grey">Local Packers & Movers</th>
                  <th className="text-light-grey">Packers & Movers</th>
                </tr>
              </thead>
              <tbody>
                <tr className="main-table">
                  <td className="table-head">Vehicle Assurance</td>
                  <td className="text-center ">
                    <FontAwesomeIcon icon={faCheck} />
                  </td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faCheck} />
                  </td>
                </tr>

                <tr className="main-table">
                  <td className="table-head">
                    Free Loading and Unloading Time
                  </td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faXmark} />
                  </td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faCheck} />
                  </td>
                </tr>
                <tr className="main-table">
                  <td className="table-head">On-Time Pickup</td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faXmark} />
                  </td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faCheck} />
                  </td>
                </tr>
                <tr className="main-table">
                  <td className="table-head">Verified Professional Driver</td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faXmark} />
                  </td>

                  <td className="text-center">
                    <FontAwesomeIcon icon={faCheck} />
                  </td>
                </tr>
                <tr className="main-table">
                  <td className="table-head">On-Demand Additional Helper</td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faXmark} />
                  </td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faCheck} />
                  </td>
                </tr>
                <tr className="main-table">
                  <td className="table-head">Free Rescheduling</td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faXmark} />
                  </td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faCheck} />
                  </td>
                </tr>
                <tr className="main-table">
                  <td className="table-head">Support Assistance</td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faXmark} />
                  </td>
                  <td className="text-center">
                    <FontAwesomeIcon icon={faCheck} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </section>

      <section>
        <div className="outer-main-div mt-4">
          <div className="main-inner-work">
            <div className="inner-work-images">
              <div className="work-text">
                <h4>Best Packers and Movers in Noida</h4>

                <p>
                  Discover top-tier relocation services with NMP Packers and
                  movers, your trusted partner for seamless moves in Noida. With
                  a focus on reliability and efficiency, we offer comprehensive
                  packing, moving, and storage solutions tailored to meet your
                  needs. Whether it's local or long-distance, residential or
                  commercial, trust us for a stress-free relocation experience.
                  Contact us today for a quote!
                </p>
              </div>
            </div>
            <div className="inner-work-images">
              <div className="work-text">
                <h4>What are NMP Packers and movers Services In Noida</h4>
                <p>
                  Explore NMP Packers and movers services in Noida for seamless
                  relocation solutions. Our expert team ensures hassle-free
                  moving with services including packing, loading,
                  transportation, and unpacking. We specialize in local and
                  intercity moves, catering to residential and commercial needs.
                  Benefit from our trusted packing materials and efficient
                  handling of delicate items. Contact us for a personalized
                  moving plan tailored to your requirements. Experience
                  reliability and professionalism with NMP Packers and movers,
                  your trusted relocation partner.
                </p>
              </div>
            </div>
            <div className="inner-work-images">
              <div className="work-text">
                <h6>Domestic Best Packers and Movers in Noida:</h6>
                <p>
                  Explore the top domestic packers and movers in Noida with our
                  expert services. Trust our reliable team for seamless
                  relocations across India. We ensure secure handling of your
                  belongings with personalized care and timely delivery. Contact
                  us today for a stress-free moving experience with Noida
                  Packers And Movers.
                </p>
              </div>
            </div>
            <div className="inner-work-images">
              <div className="work-text">
                <h6>Office Shifting Best Packers And Movers Nearby Noida:</h6>
                <p>
                  Looking for reliable packers and movers for office shifting in
                  Noida? Look no further than NMP Packers and movers. With years
                  of experience, we offer seamless relocation services tailored
                  to meet your business needs. Our trained professionals ensure
                  efficient handling of your office equipment and furniture,
                  ensuring a hassle-free move. Contact us today for a quote!
                </p>
              </div>
            </div>
            <div className="inner-work-images">
              <div className="work-text">
                <h6>Intercity Packers and Movers in Noida:</h6>
                <p>
                  Welcome to Intercity Packers and Movers Noida, your trusted
                  partner for seamless relocation services across cities. With
                  years of expertise, we specialize in safe and efficient
                  transportation of your belongings. Whether you're moving homes
                  or offices, count on our dedicated team for reliable packing,
                  loading, and unpacking services. Experience stress-free moving
                  with Intercity Packers and Movers Noida.
                </p>
              </div>
            </div>

            <div className="inner-work-images">
              <div className="work-text">
                <h6>
                  Intercity Packers and Movers in Noida:Reliable Interstate Home
                  Shifting Packers and Movers in Noida:
                </h6>
                <p>
                  Discover dependable interstate home shifting solutions with
                  Intercity Packers and Movers in Noida. Our specialty is
                  providing hassle-free, individualized moving services. Our
                  experienced team ensures smooth transitions with secure
                  packing, efficient transportation, and timely delivery.
                  Whether moving within Karnataka or across state borders, trust
                  us for reliable and stress-free home shifting. For a smooth
                  moving experience, get in touch with us right now.
                </p>
              </div>
            </div>

            <div className="inner-work-images">
              <div className="work-text">
                <h4>
                  How the home shifting services in Noida of Noida Packers And
                  Movers make moving easier for you?
                </h4>
                <p>
                  Experience seamless home shifting with NMP Packers and movers.
                  Our expert services in Noida ensure a hassle-free moving
                  experience. From packing to unpacking, we handle it all with
                  care and efficiency. Your possessions arrive at their
                  destination promptly and safely because to our committed staff
                  and dependable transportation. Trust NMP Packers and movers to
                  simplify your relocation process, offering peace of mind every
                  step of the way. <br />
                  <br />
                  Discover effortless home shifting solutions in Noida with NMP
                  Packers and movers. Our comprehensive services streamline your
                  relocation journey from start to finish. With meticulous
                  packing, secure transportation, and timely delivery, we ensure
                  your belongings arrive intact. Our experienced team handles
                  all aspects of the move, alleviating your stress and ensuring
                  a smooth transition to your new home. Count on NMP Packers and
                  movers for reliable, efficient, and customer-focused moving
                  services tailored to meet your needs. <br />
                  <br />
                  Experience seamless home shifting in Noida with Noida Packers
                  And Movers. Our dedicated services are designed to simplify
                  your relocation process. From expert packing to safe
                  transportation and unpacking, we manage every detail with
                  precision. Our knowledgeable staff makes sure your possessions
                  are treated with care and arrive at their destination on time.
                  Trust NMP Packers and movers for a stress-free moving
                  experience, where efficiency and reliability meet to exceed
                  your expectations. Make your move with confidence and ease
                  with our trusted relocation services.
                  <br />
                  <br />
                  Navigate home shifting effortlessly with NMP Packers and
                  movers in Noida. Our tailored services ensure a smooth
                  relocation experience from start to finish. With meticulous
                  packing techniques, secure transportation options, and timely
                  delivery, we prioritize the safety and convenience of your
                  belongings. Our experienced team manages all aspects of the
                  move, providing peace of mind throughout the journey. Count on
                  NMP Packers and movers for reliable, customer-centric
                  solutions that simplify your transition to a new home. Trust
                  us to handle your move with care and efficiency, making
                  relocation a seamless experience.
                  <br />
                </p>
              </div>
            </div>

            <div className="inner-work-images">
              <div className="work-text">
                <h4>An overview of the NMP Packers and movers quotation</h4>
                <p>
                  Discover hassle-free moving solutions with NMP Packers and
                  movers. Our transparent quotation process ensures competitive
                  pricing tailored to your needs. Whether you're relocating
                  locally or across India, trust our expertise for reliable
                  packing, secure transportation, and timely delivery. Get
                  started with a detailed quote today and experience seamless
                  moving services that prioritize your satisfaction.
                </p>
                <p>
                  NMP Packers and movers offer comprehensive moving solutions
                  with a clear, detailed quotation process. Our affordable
                  prices guarantee exceptional service without sacrificing cost.
                  Whether you're moving within Noida or across India, we
                  prioritize your needs with expert packing, safe
                  transportation, and on-time delivery. Experience stress-free
                  relocation with our personalized approach and transparent
                  pricing. Contact us today for a detailed quote and discover
                  reliable, efficient moving services that exceed your
                  expectations.
                </p>
              </div>
            </div>

            <div className="inner-work-images">
              <div className="work-text">
                <h4>
                  Benefits of Using NMP Packers and movers's Packing & Moving
                  Services in Noida
                </h4>
                <h5>Why NMP Packers and movers are the Best in Noida</h5>
                <h6>
                  NMP Packers and movers Services In Noida Provides Relocation
                  Services From Door To Door:
                </h6>
                <p>
                  Welcome to NMP Packers and movers, your trusted partner in
                  relocation services across Noida. We offer comprehensive
                  door-to-door relocation solutions tailored to meet your needs.
                  Whether you're moving locally or across the city, our expert
                  team ensures a seamless experience with top-notch packing,
                  loading, transportation, and unpacking services. Trust NMP
                  Packers and movers for a stress-free move, backed by years of
                  expertise and commitment to customer satisfaction.
                </p>
              </div>
            </div>
            <div className="inner-work-images">
              <div className="work-text">
                <h6>
                  NMP Packers and movers Home Shifting Service In Noida’s
                  Packing Is Impeccable:
                </h6>
                <p>
                  At NMP Packers and movers, we offer impeccable home shifting
                  services in Noida. Our expert team ensures your belongings are
                  carefully packed and transported with the utmost care. Using
                  high-quality packing materials and advanced techniques, we
                  guarantee a safe and hassle-free relocation experience. Trust
                  us to handle your move efficiently and professionally,
                  providing you with peace of mind. Choose NMP Packers and
                  movers for a seamless and stress-free home shifting service in
                  Noida.
                </p>
              </div>
            </div>
            <div className="inner-work-images">
              <div className="work-text">
                <h6>
                  NMP Packers and movers House Shifting Services In Noida
                  Provide Transport, Loading, And Unloading Services:
                </h6>
                <p>
                  Relocate seamlessly with NMP Packers and movers! We offer
                  comprehensive house shifting services in Noida, ensuring safe
                  and efficient transport, loading, and unloading of your
                  belongings. Our professional team handles your items with
                  utmost care, guaranteeing a stress-free moving experience.
                  Trust us to make your move smooth and hassle-free. Contact NMP
                  Packers and movers today for reliable and affordable moving
                  solutions.
                </p>
              </div>
            </div>
            <div className="inner-work-images">
              <div className="work-text">
                <h6>
                  Noida's NMP Packers and movers Local Movers and Packers
                  Services This Is An Feature That Saves Time And Effort:
                </h6>
                <p>
                  Experience hassle-free relocations with NMP Packers and
                  movers. Our local movers and packers services are designed to
                  save you time and effort, ensuring a smooth and efficient move
                  within Noida. We take care of everything, including loading,
                  packaging, unloading, and shipping. Trust our professional
                  team to take care of your belongings with the utmost care and
                  precision. Choose NMP Packers and movers for a stress-free
                  moving experience!
                </p>
              </div>
            </div>

            <div className="work-text">
              <h4>
                A Quick Cost Analysis Of NMP Packers and movers Home Shifting
                Services In Noida
              </h4>
              <h5>Table-1</h5>
              <div className="table-container">
                <h5>Based on BHK Type</h5>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>BHK TYPE</th>
                      <th>APPROX. COST (INR)*</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 BHK</td>
                      <td>6,090/- to 7,590/-</td>
                    </tr>
                    <tr>
                      <td>1 RK</td>
                      <td>5,250/- to 6,750/-</td>
                    </tr>
                    <tr>
                      <td>2 BHK</td>
                      <td>8,925/- to 11,425/-</td>
                    </tr>
                    <tr>
                      <td>3 BHK</td>
                      <td>13,650/- to 17,150/-</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2">
                        *Please take note that the price may change based on the
                        volume of items that need to be moved.
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </div>
          </div>

          <div className="inner-work-images">
            <div className="work-text">
              <h4>
                The Nearest Packers and Movers in Noida – NMP Packers and movers
                Dispenses With Insurance
              </h4>
              <p>
                NMP Packers and movers offers efficient and reliable services,
                ensuring your belongings are handled with utmost care. We
                understand the importance of safety, which is why we provide
                comprehensive insurance coverage to protect your valuables
                during transit. Our professional team, equipped with modern
                tools and vehicles, ensures a seamless moving experience. Choose
                NMP Packers and movers for hassle-free relocation and peace of
                mind, knowing your possessions are in safe hands. Trust us for
                timely, secure, and cost-effective moving solutions.
              </p>
            </div>
          </div>
          <div className="inner-work-images">
            <div className="work-text">
              <h4>Conclusion</h4>
              <p>
                In conclusion, NMP Packers and movers are dedicated to providing
                top-notch relocation services tailored to your unique needs. Our
                experienced team ensures a seamless and stress-free moving
                experience, handling every aspect with care and professionalism.
                From packing and loading to transportation and unloading, we
                guarantee the safe delivery of your belongings to your new
                destination. Trust NMP Packers and movers for a reliable,
                efficient, and cost-effective moving solution. Contact us today
                to experience the best in relocation services and make your move
                a hassle-free journey.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Left;
