import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import cars from "../Assets/system.webp";
import van from "../Assets/van.webp";
import { Link } from "react-router-dom";
import Form from "../Form/Form";

const CarandBikeTransportation = () => {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="banner">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Car And Bike Shifting Services</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link>Service</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details-area">
        <div className="container">
          <div className="row mt-3">
            <div className="col-lg-7 mt-3">
              <div className="service-details-wrap">
                <h1>Noida's Finest Car And Bike Shifting Services</h1>
                <p>
                  Welcome to NMP Packers and movers, your trusted partner for
                  superior Car And Bike Shifting Services in Noida. With years
                  of expertise, we offer a comprehensive range of solutions
                  tailored to meet your specific needs. Our dedicated team
                  ensures meticulous planning and execution, guaranteeing the
                  safe and timely delivery of your vehicles. Whether you're
                  moving residences or businesses, count on us for reliable and
                  efficient transport solutions. Experience peace of mind with
                  our commitment to quality service and customer satisfaction.
                  Choose Noida's finest for your vehicle and bike transportation
                  needs.To discuss your needs and obtain a personalized price,
                  get in touch with us right now.
                </p>
                <p>
                  Discover Noida's premier solution for vehicle and bike
                  transportation services. At NMP Packers and movers, we
                  specialize in seamless, secure transport of your vehicles
                  across the city and beyond. With a dedicated team and
                  state-of-the-art logistics, we ensure safe handling and timely
                  delivery. Whether relocating or transporting vehicles, trust
                  us for reliability and efficiency. Experience hassle-free
                  moving with Noida's finest in vehicle and bike transportation
                  services. Contact us today for a tailored solution that meets
                  your needs.
                </p>

                <p>
                  Explore Noida's premier choice for seamless vehicle and bike
                  transportation services at NMP Packers and movers. With
                  specialized expertise and a dedicated team, we ensure secure
                  and timely transport across Noida and beyond. Whether for
                  residential or commercial needs, trust us for reliable,
                  hassle-free moving solutions. Experience unmatched quality and
                  customer satisfaction with our tailored services. Contact us
                  today to discover how we can assist with your vehicle and bike
                  transportation requirements.
                </p>

                <div className="single-item">
                  <img
                    src={cars}
                    alt="car bike transportation services in Noida"
                  />
                </div>

                <h2 className="mt-4">
                  Hire Car &amp; Bike Transportation Services In Noida
                </h2>
                <p>
                  Explore NMP Packers and movers for reliable car and bike
                  transportation services across Noida. We specialize in safe
                  and efficient vehicle relocation, ensuring your prized
                  possessions reach their destination securely. With a team of
                  skilled professionals and modern transport vehicles, we
                  guarantee hassle-free moving experiences. Whether you're
                  shifting residences or offices, our dedicated services cater
                  to all your transportation needs. Trust NMP Packers and movers
                  for affordable rates and exceptional service quality. Contact
                  us today to book your car and bike transportation with peace
                  of mind.
                </p>
                <p>
                  Discover NMP Packers and movers for premium car and bike
                  transportation services in Noida. Our expert team ensures the
                  safe and timely delivery of your vehicles to any destination
                  across the city. With a focus on reliability and customer
                  satisfaction, we offer customized transportation solutions for
                  individuals and businesses alike. From planning and packing to
                  secure transit and delivery, we handle every aspect with
                  precision. Choose NMP Packers and movers for a seamless moving
                  experience and rest assured your vehicles are in capable
                  hands. Get in touch with us right now to arrange your
                  transportation needs.
                </p>
                <p>
                  NMP Packers and movers is your trusted partner for superior
                  car and bike transportation services in Noida. With years of
                  experience, we specialize in delivering your vehicles safely
                  and efficiently to any location within Noida and beyond. Our
                  dedicated team employs advanced packing techniques and secure
                  transport options to ensure your vehicles arrive in pristine
                  condition. Whether you're relocating locally or nationally,
                  our personalized approach guarantees peace of mind and
                  satisfaction. Count on Noida Packers And Movers for
                  competitive pricing, prompt service, and unparalleled
                  expertise in vehicle transportation. Contact us today to
                  experience seamless moving solutions tailored to your needs.
                </p>

                <h2>Why Choose Us?</h2>
                <p>
                  Choose NMP Packers and movers for your relocation needs and
                  experience unparalleled service excellence. With years of
                  expertise, we ensure seamless moves tailored to your
                  requirements. Our trained professionals handle your belongings
                  with utmost care, ensuring safe transport and timely delivery.
                  We offer competitive pricing without compromising on quality,
                  making us the preferred choice in Noida. Trust us for
                  efficient packing, secure storage options, and reliable
                  customer support throughout your moving journey. Discover why
                  countless clients rely on us for their relocation needs.
                </p>
                <p>
                  Discover why NMP Packers and movers stands out in the
                  industry. Our dedication to ensuring client happiness informs
                  every facet of our service. From initial consultation to final
                  delivery, we prioritize transparency and reliability. Our
                  fleet of modern vehicles and advanced packing materials ensure
                  your belongings remain safe during transit. Whether it's
                  residential or commercial relocation, our dedicated team goes
                  the extra mile to exceed your expectations. Experience peace
                  of mind with our comprehensive insurance coverage and 24/7
                  customer assistance. Choose us for a stress-free moving
                  experience in Noida.
                </p>
                <p>
                  NMP Packers and movers sets itself apart with a proven track
                  record of excellence. Our skilled team is adept at handling
                  complex logistics, ensuring smooth transitions across Noida
                  and beyond. We pride ourselves on personalized service,
                  understanding each client's unique needs and delivering
                  tailored solutions. Whether it's packing delicate items or
                  coordinating large-scale relocations, we guarantee
                  professionalism at every step. Trust us for efficiency,
                  integrity, and a commitment to making your move hassle-free.
                  Join countless satisfied customers who choose us for their
                  moving needs.
                </p>
              </div>
              <div className="service-sidebar-area m-3 text-center">
                <div className="service-list service-card">
                  <div className="left-main-div">
                    <h3 className="service-details-title">Our Services</h3>
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Local Household Shifting
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Domestic Shifting Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        International Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Car & Bike Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Warehouse Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Packing Unpacking Services
                      </li>
                    </ul>
                  </div>
                  <div className="service-image">
                    <img src={van} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-3">
              <Form activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarandBikeTransportation;
