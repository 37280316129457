import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import "./Footer.css";
import call from "../Assets/viber.webp";
import whatsapp from "../Assets/whatsapp.webp";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleFooter = () => {
    if (window.innerWidth < 1900) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="icon-container">
        <Link to="tel:+91-7303092212" className="icon call">
          <img className="icon-image icon-image-call" src={call} alt="" />
        </Link>
        <Link
          to="https://api.whatsapp.com/send?phone=+91-7303092212 &amp;text= I am looking for shifting with your company"
          className="icon whatsapp"
        >
          <img
            className="icon-image icon-image-whatsapp"
            src={whatsapp}
            alt=""
          />
        </Link>
      </div>
      <footer className="footer">
        <div className="footer-container">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-content">
                  <h5>Address</h5>
                  <h6>
                    Gaur City Centre, Gaur Chowk, West, Sector 4, Greater Noida,
                    Uttar Pradesh 223207
                  </h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-contents">
                  <h5>Contact Us</h5>
                  <Link to="tel:+91-7303092212">
                    <h6>Phone: +91-7303092212</h6>
                  </Link>
                  {/* <Link to="tel:+91-80412-22228">
                    <h6>Phone: +91-80412-22228</h6>
                  </Link> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-contents">
                  <h5>Mail Us</h5>
                  <Link to="mailto:noidamuvers@gmail.com">
                    <h6>G-mail: noidamuvers@gmail.com</h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="footer-icons">
            <ul className="footer-list">
              <Link to="/AboutUs">
                <li onClick={handleFooter}>About Us</li>
              </Link>
              <Link to="/RefundPolicy">
                <li onClick={handleFooter}>Refund Policy</li>
              </Link>
              <Link to="/policy">
                <li onClick={handleFooter}>Privacy Policy</li>
              </Link>
              <Link to="/terms">
                <li onClick={handleFooter}>Terms and Conditions</li>
              </Link>
              <Link to="/form">
                <li onClick={handleFooter}>Contact Us</li>
              </Link>
            </ul>
            <div className="social-icons">
              <Link to="#">
                <FaFacebook />
              </Link>
              <Link to="#">
                <FaTwitter />
              </Link>
              <Link to="#">
                <FaInstagram />
              </Link>
              <Link to="#">
                <FaLinkedin />
              </Link>
              <Link to="#">
                <FaYoutube />
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <h6>
            &copy; {new Date().getFullYear()} NMP Packers and movers || Design
            By &nbsp;
            <Link to="https://www.futuretouch.in/">
              Future IT Touch Pvt. Ltd
            </Link>
            &nbsp;|| All Rights Reserved.
          </h6>
        </div>
      </footer>
    </>
  );
};

export default Footer;
