import React, { useState, useEffect } from "react";
// import image1 from "../Assets/image3.webp";
// import image2 from '../Assets/image4.webp';
// import image3 from '../Assets/image1.webp';
// import image4 from '../Assets/image2.webp';
import Form from "../Form/Form";

const Packing = () => {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Service-header mt-3">
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5 col-sm-5 form-design sticky">
            <Form activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          <div className="col-md-7 mt-2 col-sm-7">
            <h2>
              <strong>Services of NMP Packers and movers </strong>
            </h2>
            <p>
              Welcome to NMP Packers and movers, your trusted partner in
              relocation services. We specialize in a wide array of moving
              solutions tailored to meet your needs. Whether you're relocating
              your home, office, or vehicle, we ensure a seamless experience
              from start to finish. Our services include expert packing using
              high-quality materials, secure transportation with well-maintained
              vehicles, and on-time delivery to your new destination.
              Additionally, we offer specialized services such as fragile item
              packing, furniture assembly, and temporary storage solutions.
              Choose NMP Packers and movers for reliable, efficient, and
              cost-effective moving services that prioritize your peace of mind.
            </p>
            <p>
              Explore our comprehensive range of packers and movers services in
              Noida designed to ensure a smooth relocation experience. We
              specialize in home relocation, office shifting, vehicle
              transportation, and warehousing solutions. Our dedicated team
              handles each move with care, ensuring safety and efficiency from
              packing to unpacking. Whether you're moving locally or across the
              country, trust NMP Packers and movers for reliable and
              professional services tailored to your needs.
            </p>
            <p>
              Discover our comprehensive range of services at NMP Packers and movers. Whether you're moving locally or across the country,
              we offer expert packing, safe transportation, and timely delivery
              of your belongings. Our services include home relocation, office
              shifting, vehicle transportation, and secure storage solutions.
              With a commitment to quality and customer satisfaction, we ensure
              a hassle-free moving experience. Trust NMP Packers and movers for reliable, efficient, and affordable moving solutions
              tailored to meet your needs.
            </p>
            <h3>
              Reasons To Choose NMP Packers and movers In Noida!
            </h3>
            <p>
              Welcome to NMP Packers and movers, your trusted relocation
              partner in Noida. Choosing us means opting for seamless
              relocation solutions tailored to your needs. With years of
              experience, we offer expert packing, safe transportation, and
              timely delivery. Our trained team ensures utmost care for your
              belongings. We pride ourselves on transparency, competitive
              pricing, and dedicated customer support, ensuring a stress-free
              move every time. Whether it's home, office, or vehicle relocation,
              trust NMP Packers and movers for a smooth journey to your
              new destination.
            </p>
            <p>
              Welcome to NMP Packers and movers, your premier choice for
              relocation services in Noida. Our commitment to excellence is
              evident in every move we undertake. With a focus on efficiency and
              reliability, we offer comprehensive packing, secure
              transportation, and punctual delivery. Our team of skilled
              professionals ensures careful handling of your belongings,
              providing peace of mind throughout the relocation process. We
              prioritize customer satisfaction, offering competitive rates and
              personalized service tailored to your requirements. Trust
              NMP Packers and movers for a seamless relocation experience,
              whether for home, office, or vehicle moving needs in Noida.
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>

      <div className="container mt-4 ">
        <div className="row">
          <div className="col-md-12 col-sm-12  packing-content">
            <div className="container cost-table-container">
              <h2>Packers and Movers Cost Calculation</h2>
              <table className="cost-table">
                <thead>
                  <tr>
                    <th>Local Shifting</th>
                    <th>Approximate Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1 BHK House shifting</td>
                    <td>Rs 5,000 to Rs 9,000</td>
                  </tr>
                  <tr>
                    <td>2 BHK House shifting</td>
                    <td>Rs 9,000 to Rs 12,000</td>
                  </tr>
                  <tr>
                    <td>3 BHK House shifting</td>
                    <td>Rs 12,000 to Rs 18,000</td>
                  </tr>
                  <tr>
                    <td>4 BHK House shifting</td>
                    <td>Rs 16,000 to Rs 22,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packing;
