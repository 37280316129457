import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import deliver from "../Assets/domestic1.webp";
import deliver2 from "../Assets/delever.webp";
import { Link } from "react-router-dom";
import Form from "../Form/Form";
const DomesticShiftingServices = () => {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="banner-loading">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Domestic Shifting Services</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link>Service</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details-area">
        <div className="container">
          <div className="row mt-3">
            <div className="col-lg-7 mt-3">
              <div className="service-details-wrap">
                <h2>Best Domestic shifting services In Noida</h2>

                <p className="text-justify">
                  NMP Packers and movers offer top-notch domestic shifting
                  services in Noida. With a focus on reliability and
                  efficiency, they ensure smooth transitions for households
                  across the city. Their services include packing, loading,
                  transportation, and unpacking, handled by trained
                  professionals using quality materials. Whether moving locally
                  or across states, they prioritize customer satisfaction with
                  timely deliveries and competitive pricing. Trust Noida
                  Packers And Movers for a stress-free relocation experience
                  tailored to your needs.
                </p>

                <p className="text-justify">
                  NMP Packers and movers excel in providing exceptional
                  domestic shifting services in Noida. Known for their
                  meticulous planning and seamless execution, they cater to
                  diverse relocation needs with precision. From careful packing
                  of belongings to secure transportation and unpacking at the
                  destination, every step is managed by skilled personnel. Their
                  commitment to quality service ensures peace of mind during the
                  entire moving process, making them a trusted choice for
                  efficient and reliable home relocation in Noida.
                </p>

                <p className="text-justify">
                  NMP Packers and movers specialize in top-notch domestic
                  shifting services within Noida. They offer tailored
                  relocation solutions, ensuring seamless transitions for
                  households. Using premium packing materials and skilled
                  professionals, they ensure secure handling and timely
                  delivery. With a focus on customer satisfaction and
                  competitive pricing, they provide peace of mind throughout the
                  moving process. Count on NMP Packers and movers for
                  reliable and efficient home relocation services tailored to
                  meet individual needs in Noida.
                </p>

                <div className="single-item">
                  <img
                    src={deliver}
                    alt="car bike transportation services in Noida"
                  />
                </div>

                <h2 className="mt-4">
                  Why One Should Hire Domestic Shifting services in Noida?
                </h2>

                <p className="text-justify">
                  When relocating within Noida, hiring domestic shifting
                  services offers numerous advantages. Professional packers and
                  movers ensure careful handling of your belongings, minimizing
                  the risk of damage during transit. They provide specialized
                  packing materials tailored to different items, ensuring secure
                  packaging. With their expertise, they manage logistics
                  efficiently, ensuring timely and reliable service. Moreover,
                  they offer insurance options for added protection against
                  unforeseen events. Choosing domestic shifting services in
                  Noida simplifies the relocation process, providing
                  convenience and peace of mind throughout your move.
                </p>

                <p className="text-justify">
                  Opting for domestic shifting services in Noida provides
                  several compelling advantages. Professional packers and movers
                  bring expertise in handling diverse household items, ensuring
                  they are packed securely and transported safely to their
                  destination. They use specialized packing materials suitable
                  for fragile and valuable items, reducing the risk of damage
                  during transit. Additionally, movers offer comprehensive
                  logistics management, ensuring timely pickup, transportation,
                  and delivery according to schedule. Their insurance options
                  provide added assurance, covering any unexpected incidents
                  during the move. By hiring domestic shifting services,
                  residents of Noida can experience a hassle-free relocation
                  process, supported by skilled professionals dedicated to
                  customer satisfaction.
                </p>

                <p className="text-justify">
                  Choosing domestic shifting services in Noida ensures a
                  smooth and efficient relocation experience. Professional
                  packers and movers handle all aspects of the move with
                  precision and care, from packing and loading to transportation
                  and unloading at the destination. They offer specialized
                  packing materials for different items, ensuring optimal
                  protection during transit. With their logistical expertise and
                  commitment to customer satisfaction, domestic shifting
                  services provide peace of mind, making the relocation process
                  in Noida convenient and stress-free.
                </p>

                <h2>Why Choose Us?</h2>
                <p className="text-justify">
                  Choose NMP Packers and movers for a seamless relocation
                  experience. With years of expertise, we offer tailored
                  solutions for your moving needs. Our trained professionals
                  handle your belongings with utmost care, ensuring safe
                  transport and on-time delivery. We provide competitive
                  pricing, transparent communication, and personalized service
                  to ensure your satisfaction. Whether you're moving locally or
                  across the country, trust us to make your move stress-free and
                  efficient. Discover why we're the preferred choice for
                  reliable and hassle-free moving solutions.
                </p>
                <p className="text-justify">
                  NMP Packers and movers stands out with a proven track
                  record of excellence in the relocation industry. Our dedicated
                  team prioritizes customer satisfaction, offering meticulous
                  planning and execution for every move. From packing to
                  unpacking, we ensure meticulous handling of your belongings.
                  Our commitment to reliability and integrity means you can
                  trust us with your move, whether residential or commercial.
                  Experience peace of mind with our comprehensive moving
                  services tailored to meet your needs efficiently.
                </p>
                <h2>Experience the Difference:</h2>
                <p className="text-justify">
                  Experience the Difference with NMP Packers and movers!
                  We offer seamless relocation services tailored to your needs.
                  With years of expertise, we ensure smooth transitions for
                  homes and offices alike. Our dedicated team handles every
                  aspect, from packing to unpacking, with care and efficiency.
                  Whether moving locally or across the country, trust us for a
                  stress-free experience. Visit our website today to discover
                  why we're your preferred choice for reliable and professional
                  moving services.
                </p>
                <h5>
                  Efficiency, Reliability, Peace of Mind - That's Noida
                  Packers And Movers for You!
                </h5>
              </div>
              <div className="service-sidebar-area m-3 text-center">
                <div className="service-list service-card">
                  <div className="left-main-div">
                    <h3 className="service-details-title">Our Services</h3>

                    <ul>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Local Household Shifting
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Domestic Shifting Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        International Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Car & Bike Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Warehouse Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Packing Unpacking Services
                      </li>
                    </ul>
                  </div>
                  <div className="service-image">
                    <img src={deliver2} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-3">
              <Form activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DomesticShiftingServices;
