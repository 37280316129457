import React, { useState } from "react";
import cars from "../Assets/abt-d.webp";
import { Link } from "react-router-dom";
import Form from "../Form/Form";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <section className="banner about-bg">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>About Us</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link>About Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details-area">
        <div className="container">
          <div className="row mt-3">
            <div className="col-lg-7 mt-3">
              <div className="service-details-wrap">
                <h1>About Us NMP Packers and movers</h1>
                <p>
                  At NMP Packers and movers, we pride ourselves on
                  providing reliable and efficient relocation services. With
                  years of experience, our expert team ensures a seamless moving
                  experience, handling everything from packing to transportation
                  with utmost care. We cater to residential, commercial, and
                  industrial clients, ensuring personalized solutions for every
                  move. Our commitment to customer satisfaction, timely
                  delivery, and competitive pricing makes us the preferred
                  choice for all your relocation needs in Noida. Trust us
                  for a hassle-free move.
                </p>
                <p>
                  NMP Packers and movers is your trusted partner for all
                  relocation needs. With extensive experience and a skilled
                  team, we provide comprehensive moving solutions, including
                  packing, loading, transportation, and unpacking. Our services
                  cater to residential, commercial, and industrial clients,
                  ensuring a personalized approach for every move. We prioritize
                  safety and efficiency, using high-quality materials and
                  advanced techniques. Our commitment to excellence and customer
                  satisfaction has earned us a reputation for reliability and
                  professionalism. Choose NMP Packers and movers for a
                  seamless, hassle-free relocation experience at competitive
                  prices.
                </p>

                <p>
                  NMP Packers and movers is dedicated to providing
                  exceptional relocation services for both individuals and
                  businesses. With a wealth of experience and a professional
                  team, we ensure every move is handled with the utmost care and
                  efficiency. Our comprehensive services include meticulous
                  packing, secure loading, safe transportation, and organized
                  unpacking. We tailor our solutions to meet the specific needs
                  of residential, commercial, and industrial clients. Our
                  unwavering commitment to customer satisfaction, coupled with
                  timely delivery and competitive rates, makes us the go-to
                  choice for stress-free relocations in Noida. Trust us to
                  move you with excellence.
                </p>

                <div className="single-item">
                  <img
                    src={cars}
                    alt="car bike transportation services in Noida"
                  />
                </div>

                <h2 className="mt-4">
                  Welcome to NMP Packers and movers: Your Trusted
                  Relocation Partner
                </h2>
                <p>
                  Welcome to NMP Packers and movers: Your Trusted
                  Relocation Partner! We specialize in seamless, stress-free
                  moving services across Noida. Our experienced team handles
                  everything from packing to transportation with utmost care and
                  efficiency. Whether it’s a local move or a long-distance
                  relocation, we ensure your belongings reach their destination
                  safely and on time. Choose us for professional service,
                  reliability, and peace of mind. Experience a hassle-free move
                  with NMP Packers and movers, where your satisfaction is
                  our priority.
                </p>
                <p>
                  Welcome to NMP Packers and movers: Your Trusted
                  Relocation Partner! We specialize in seamless, stress-free
                  moving services across Noida. Our experienced team handles
                  everything from packing to transportation with utmost care and
                  efficiency. Whether it’s a local move or a long-distance
                  relocation, we ensure your belongings reach their destination
                  safely and on time. Choose us for professional service,
                  reliability, and peace of mind. Experience a hassle-free move
                  with NMP Packers and movers, where your satisfaction is
                  our priority. Contact us today for a smooth and efficient
                  relocation experience.
                </p>
                <p>
                  Welcome to NMP Packers and movers: Your Trusted
                  Relocation Partner! We specialize in seamless, stress-free
                  moving services across Noida. Our experienced team handles
                  everything from packing to transportation with utmost care and
                  efficiency. Whether it’s a local move or a long-distance
                  relocation, we ensure your belongings reach their destination
                  safely and on time. Choose us for professional service,
                  reliability, and peace of mind. Experience a hassle-free move
                  with NMP Packers and movers, where your satisfaction is
                  our priority. Contact us today for a smooth, efficient, and
                  affordable relocation experience!
                </p>

                <h2> Our Mission:</h2>
                <p>
                  At NMP Packers and movers, our mission is to provide
                  exceptional relocation services that prioritize customer
                  satisfaction, efficiency, and safety. We aim to make every
                  move seamless and stress-free by utilizing our expertise,
                  modern equipment, and a dedicated team. Committed to
                  excellence, we strive to handle your belongings with the
                  utmost care and professionalism, ensuring they reach their
                  destination intact. Our goal is to build lasting relationships
                  with our clients through reliable and personalized moving
                  solutions.
                </p>
                <p>
                  At NMP Packers and movers, our mission is to redefine
                  the moving experience with a commitment to reliability,
                  integrity, and customer-centricity. We endeavor to deliver
                  seamless relocation solutions that exceed expectations,
                  ensuring every client's journey is smooth and stress-free.
                  With a focus on quality and efficiency, we leverage advanced
                  technology and trained professionals to safeguard your
                  possessions during transit. Our aim is to be the trusted
                  partner you can rely on for all your moving needs, fostering
                  enduring relationships built on transparency and excellence.
                </p>
                <h2> Our Vision:</h2>
                <p>
                  Our Vision is to redefine the standards of packing and moving
                  services in Noida, ensuring unparalleled customer
                  satisfaction and reliability. We aim to become the most
                  trusted name in the industry by consistently delivering
                  efficient, safe, and innovative relocation solutions. Through
                  continuous improvement and embracing technology, we strive to
                  exceed expectations, making every move a seamless experience
                  for our customers.
                </p>
                <p className="mb-4">
                  At NMP Packers and movers, our vision is rooted in
                  revolutionizing the relocation experience with unwavering
                  commitment to excellence and customer satisfaction. We aspire
                  to set new benchmarks in the packing and moving industry by
                  providing seamless, secure, and personalized services tailored
                  to meet every client's unique needs. Our goal is to be
                  recognized as the foremost choice for reliable and innovative
                  relocation solutions in Noida. Through dedication to
                  quality, transparency, and continuous improvement, we aim to
                  build enduring relationships and ensure peace of mind for
                  every move we handle.
                </p>
              </div>
              {/* <div className="service-sidebar-area m-3 text-center">
                <div className="service-list service-card">
                  <div className="left-main-div">
                    <h3 className="service-details-title">Our Services</h3>
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Local Household Shifting
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Domestic Shifting Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        International Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Car & Bike Transportation
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Warehouse Services
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faCheck} />
                        Packing Unpacking Services
                      </li>
                    </ul>
                  </div>
                  <div className="service-image">
                    <img src={van} alt="" />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-5 mt-3">
              <Form activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
