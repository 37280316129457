import React from "react";
import { Link } from "react-router-dom";

const Policy = () => {
  return (
    <div>
      <section className="banner-policy">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Privacy Policy</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Homes</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link to="/services">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="main-privacy ">
        <div className="container">
          <div className="row">
            <h1>Privacy Policy</h1>
            <p>
              At NMP Packers and Movers, your privacy is of utmost importance to
              us. This policy outlines how we handle, collect, and use the
              personal information you provide on our website.
            </p>
            <p>We may gather the following types of details from you:</p>
            <p>
              Personal details like your name, phone number, email address, and
              residential details.
            </p>

            <p>
              Move-specific details, such as your current address, destination
              address, moving date, and the type of move you are opting for.
              Additional details regarding your relocation such as item
              inventory, packaging requirements, and insurance preferences.
            </p>

            <h2>How we share your information</h2>

            <p>
              Your personal information may be shared with partners, vendors, or
              service providers who work with us to deliver our services. We may
              also disclose your information if legally obligated to do so, or
              to protect our rights or the rights of others.
            </p>

            <h2>Information security</h2>

            <p>
              We use reasonable security measures to safeguard your personal
              information from unauthorized access or misuse. However, please
              understand that no online transmission or storage method is
              completely secure.
            </p>

            <h2>Third-party links</h2>
            <p>
              Our website may contain links to third-party sites. We have no
              control for these websites' content or privacy policies. Before
              providing any personal information, we advise reading their
              privacy policies.
            </p>

            <h2>Children’s Privacy</h2>
            <p>
              Our services are not directed at children under the age of 18, and
              we do not knowingly collect personal data from them.
            </p>

            <h2>Changes to this privacy policy</h2>

            <p>
              This privacy policy may be revised periodically without notice. We
              encourage you to review it regularly to stay informed about any
              updates.
            </p>

            <p>
              If you have any questions or concerns regarding our privacy
              policy, feel free to reach out at noidamuvers@gmail.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
