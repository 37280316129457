import React from "react";
import { Link } from "react-router-dom";

const RefundPolicy = () => {
  return (
    <>
      <section className="banner-terms ref">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="main-inner-banner-text">
              <div className="ft-breadcrumb-content">
                <h2>Refund Policy</h2>
                <div className="ft-breadcrumb-list ul-li">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link>/</Link>
                    </li>
                    <li>
                      <Link to="/services">Refund Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="main-privacy">
        <div className="container">
          <div className="row">
            <h1>Refund Policy</h1>
            <p>
              At NMP Packers and movers, we are committed to providing our
              customers with high- quality moving services. We are aware that
              there are situations in which our services may need to be canceled
              or refunded. This Refund Policy outlines our policies and
              procedures for cancellations and refunds.
            </p>

            <h2>Cancellation and Refund Requests</h2>
            <p>
              Please let us know as soon as possible if you need to terminate
              our services. We require a minimum of 48 hours notice for
              cancellation of our services. To request a refund, please email us
              at noidamuvers@gmail.com with your booking details and the reason
              for the cancellation.
            </p>

            <h2>Refund Eligibility</h2>

            <p>
              We will review your cancellation request and determine your
              eligibility for a refund. Refunds may be granted in the following
              circumstances: If you cancel your booking at least 48 hours before
              the scheduled move, you may be eligible for a full refund.
            </p>
            <p>
              If we are unable to provide our services due to unforeseen
              circumstances, such as extreme weather or mechanical issues, you
              will be eligible for a full refund.
            </p>

            <p>
              If you cancel your booking within 48 hours of the scheduled move,
              we may charge a cancellation fee, and the refund amount may be
              reduced based on our expenses incurred for the move. Refund
              Process.
            </p>

            <p>
              Once we receive your cancellation request, we will review your
              eligibility for a refund. If you are eligible for a refund, we
              will process the refund within 7-10 business days. The same
              payment method that was used to make the reservation will be
              utilized to execute the refund. Non-Refundable Items
            </p>

            <p>
              Please note that certain items, such as packing materials, may not
              be eligible for a refund.
            </p>

            <h2>Changes to Refund Policy</h2>
            <p>
              We have the right to modify our refund policy at any time. Any
              updates will be communicated to you by publishing the updated
              policy on our website. If you have any questions or concerns about
              our Refund Policy, please contact us at noidamuvers@gmail.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
